import logger from './logger.ts';
import { app } from 'o365-modules'
namespace ImportHelpers {

    let importMaps: Partial<{ imports: Record<string, string>, scopes: Record<string, Record<string, string>> }> | null = null;
    export const cdnBaseUrl = (document.querySelector<HTMLMetaElement>('meta[name="o365-cdn-base-url"]'))?.content;

    export function getImportMaps() {
        if (importMaps == null) {
            try {
                importMaps = JSON.parse(getImportMapNode()?.innerText ?? '{}');
            } catch (ex) {
                logger.error('Could not parse import maps\n', ex)
                importMaps = {};
            }
        }
        return importMaps!;
    }

    export function getLibUrl(pLib: string) {
        let filePath: string | null = null;

        if (pLib.startsWith('./')) {
            // TODO: smarter resolve, probably will need import.meta here
            pLib = pLib.replace('./', `/nt/scripts/apps/${app.id}/`);
        } else if (pLib.includes('/')) {
            filePath = pLib.split('/').slice(1).join('/');
            pLib = pLib.split('/')[0] + '/';
        }

        const maps = getImportMaps();

        if (maps.imports && maps.imports[pLib] != null) {
            pLib = maps.imports[pLib];
        }

        if (filePath) {
            pLib = pLib + filePath;
        }

        return pLib;
    }

    export function loadStyle(pCssUrl: string, pSkipUrlResolve = false) {
        let head = document.head;
        let link = document.createElement("link");
        let href = null;

        href = pSkipUrlResolve
            ? pCssUrl
            : getLibUrl(pCssUrl);

        if (document.querySelector(`[href="${href}"]`)) { return; }

        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = href;

        let resolve = () => { };
        let reject = (_?: any) => { };
        const promise = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
        });
        link.addEventListener('error', (pEx) => {
            reject(pEx);
        }, {
            once: true
        });
        link.addEventListener('load', () => {
            resolve();
        }, {
            once: true
        });
        head.appendChild(link);
        return promise;
    }

    /**
     * Load library css from appcache or cdn depedning where the script import is coming from  
     * @param pFileName Name of the css file to bel loaded, without ./
     * @param pMetaUrl Pass the import.meta.url property
     */
    export function loadLibraryStyle(pFileName: string, pMetaUrl: string) {
        let url = '';
        if (pMetaUrl.includes('nt/scripts/apps/')) {
            const scope = `/${pMetaUrl.split('/').slice(3, -1).join('/')}/`;
            const maps = getImportMaps();
            url = maps.scopes?.[scope]?.[`${scope}${pFileName}`] ?? '';
        } else {
            url = pMetaUrl.split('/').slice(0, -1).join('/') + `/${pFileName}`;
        }

        if (!url) {
            logger.warn(`Could not resolve url for ${pFileName}`);
            return;
        }
        loadStyle(url, true);
    }

    export function loadScript(pFileName: string, pSkipUrlResolve = false) {
        const script = document.createElement("script");
        let src = null;

        src = pSkipUrlResolve
            ? pFileName
            : getLibUrl(pFileName);

        if (document.querySelector(`[src="${src}"]`)) { return; }

        script.type = 'text/javascript';
        script.src = src;
        let resolve = () => { };
        let reject = (_?: any) => { };
        const promise = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
        });
        script.addEventListener('error', (pEx) => {
            reject(pEx);
        }, {
            once: true
        });
        script.addEventListener('load', () => {
            resolve();
        }, {
            once: true
        });
        document.body.append(script);

        return promise;
    }

    export function getTemplate(pName: string) {
        return fetch(getLibUrl(pName))
            .then(response => response.text())
            .then(data => {
                return data;
            });
    }

    export function getCdnProxyUrl(alias: string) {

        let filePath: string | null = null;

        let correctedAlias = alias;

        if (correctedAlias.includes('/')) {
            filePath = correctedAlias.split('/').slice(1).join('/');
            correctedAlias = correctedAlias.split('/')[0] + '/';
        }
        const url = getLibUrl(correctedAlias).split(correctedAlias)[1];

        return `/cdn/${correctedAlias}${url}${filePath}`;
    }

    export function getImportMapNode() {
        return document.querySelector<HTMLElement>('script[type="importmap"]') ?? document.querySelector<HTMLElement>('script[type="importmap-shim"]')
    }

}

export default ImportHelpers